<template>
	<div class="min-vh-100">
		<address></address>
		<base-spinner v-if="isLoading"></base-spinner>		
		<div v-else>
			<div class="container mx-auto pt-4 pb-5 mt-3">
				<div class="text-center">
					<h3>{{username}}</h3>
				</div>				
				<div class="accordion mt-5" id="ordersAccordion">
					<div class="accordion-item px-3">
						<h3 class="mt-2">Orders</h3>						
					</div>
					<div class="accordion-item" v-for="order in orders" :key="order.id">
						<h2 class="accordion-header" :id="'heading' + order.id">
							<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" :data-bs-target="'#collapse' + order.id" aria-expanded="false" :aria-controls="'collapse' + order.id" >
								{{order['product']['name']}}
							</button>							
						</h2>
						<div :id="'collapse' + order.id" class="accordion-collapse collapse " :aria-labelledby="'heading' + order.id" data-bs-parent="#ordersAccordion">
							<Order :order=order @cancel="cancelOrder(order)"></Order>	
						</div>
					</div>					
				</div>
				<div class="accordion mt-5" id="addressesAccordion">
					<div class="accordion-item px-3">
						<div class="row">
							<div class="col-6">
								<h3 class="mt-2">Addresses</h3>	
							</div>
							<div class="col-6">
								<button class="btn btn-lg ms-auto float-end" v-on:click="newAddressModal.show()">
									<h2 class="bi bi-plus-circle-fill ms-auto" style="color:blue;text-align: right;"></h2>									
								</button>								
							</div>
						</div>											
					</div>
					<div class="row">
						<div class="col-md-12 col-lg-3" v-for="address in addresses" :key="address.id">
							<Address :key="address.id" :address=address></Address>
						</div>
					</div>				
				</div>
			</div>			
		</div>
		<div class="modal fade" id="newAddressModal" ref="newAddressModal">
			<div class="modal-dialog modal-dialog-scrollable">
				<div class="modal-content p-3">
					<div class="modal-header">
						<h3>New Address</h3>
						<button type="button" class="btn-close" v-on:click="newAddressModal.hide()"></button>
					</div>
					<div class="modal-body">
						<div class="mb-3">
							<label for="" class="form-label">Name*</label>
							<input class="form-control" v-model.trim="name">
						</div>
						<div class="mb-3">
							<label for="" class="form-label">Door*</label>
							<input class="form-control" v-model.trim="door">
						</div>
						<div class="mb-3">
							<label for="" class="form-label">Street/Apartment*</label>
							<input class="form-control" v-model.trim="street">
						</div>
						<div class="mb-3">
							<label for="" class="form-label">Landmark</label>
							<input class="form-control" v-model.trim="landmark">
						</div>
						<div class="mb-3">
							<label for="" class="form-label">City*</label>
							<!-- <select class="form-control" v-model.trim="city" id="">
								<option v-for="city in cities" :key="city" :value=city>{{city}}</option>
							</select> -->
							<input class="form-control" v-model.trim="city">
						</div>
						<div class="mb-3">
							<label for="" class="form-label">State*</label>
							<select class="form-control" v-model.trim="state" id="">
								<option value="TamilNadu">TamilNadu</option>								
							</select>
						</div>
						
						<div class="mb-3">
							<label for="" class="form-label">Pincode*</label>
							<input type="text" maxlength="6" class="form-control" v-model.trim="pincode">
						</div>
						<div class="mb-3">
							<label for="" class="form-label">Phone*</label>
							<input class="form-control" maxlength="10" v-model.trim="phone">
						</div>
						<div class="text-center">
							<base-button type="button mt-3" v-on:click="addAddress">
								<h6>Add Address</h6>
							</base-button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Order from '@/components/Profile/Order.vue';
import Address from '@/components/Profile/Address.vue';
import { Modal } from 'bootstrap';
export default {
    components: { 
		Order, Address
	},
	data(){
		return {
			door : '',
			street : '',
			landmark : '',
			city : '',
			pincode : '',
			name : '',
			phone : '',
			cities : ['Gobi','Erode','Tirupur','Coimbatore'],
			state: '',
			isLoading : false,
			newAddressModal: null,
		}
	},
	methods: {
		async loadOrders(){
			if(this.isAuth){				
				this.isLoading = true;
				try {
					await this.$store.dispatch('loadOrders');
					this.isLoading = false;
				}
				catch(error){
					console.log(error);
				}	
			}				
		},
		async addAddress(){
			if(this.isAuth){
				this.isLoading = true;
				try {
					var success = await this.$store.dispatch('addAddress', {
						door : this.door,
						street : this.street,
						landmark : this.landmark,
						city : this.city,
						state : this.state,
						pincode : this.pincode,
						phone : this.phone,
						name : this.name
					});
					this.isLoading = false;
					if(success){
						this.newAddressModal.hide();	
					}
					this.door = '';
					this.street = '';
					this.landmark = '';
					this.city = '';
					this.state = '';
					this.pincode = '';
					this.phone = '';
					this.name = ''
				}
				catch(error){
					console.log(error);
				}	
			}			
		},
		async loadAddresses(){
			if(this.isAuth){				
				this.isLoading = true;
				try {
					await this.$store.dispatch('loadAddresses');
					this.isLoading = false;
				}
				catch(error){
					console.log(error);
				}	
			}				
		},
		async cancelOrder(order){
			if(this.isAuth){				
				this.isLoading = true;
				try {
					await this.$store.dispatch('cancelOrder', {
						orderId : order['id']
					});
					this.isLoading = false;
				}
				catch(error){
					console.log(error);
				}	
			}	
		}
	},
	computed: {
		isAuth(){
			return this.$store.getters.isAuth;
		},
		username(){
			return this.$store.getters.username;
		},
		orders() {
			return this.$store.getters['orders'];
		},
		addresses() {
			return this.$store.getters['addresses'];
		},
	},
	created(){		
		this.loadOrders();
		this.loadAddresses();
		console.log(this.addresses);
		console.log(this.orders);
	},
	mounted(){
		this.newAddressModal = new Modal(this.$refs.newAddressModal);
	}
}
</script>

<style>

</style>
